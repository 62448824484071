import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OVERSIGHT_STATUS } from '@shared/constants/ume-constants';
import { IFollowupSummary } from '@shared/follow-up/models/followup-model';
import { default as moment } from 'moment';
import { NgxFloatUiTriggers } from 'ngx-float-ui';
import { Observable } from 'rxjs';
import {
  actionRaised$,
  ddqNotifications,
  followups$,
  getShareAllEnabled,
  notInvitedCompanies$,
  numberOfSharing$,
  pendingRequests$,
  reportingSummary$,
} from 'src/app/routes/dashboard/repository/dashboard.repository';

import {
  DashboardActionRaisedSummary,
  DashboardNotInvitedCompaniesSummary,
  DashboardOversightSummaryBean,
  DashboardPendingRequestsSummary,
  DDQ_SUMMARY_STATUS,
  DDQSummary,
  IDashboardReportingConfiguration,
  MyProfileDDQNotifications,
  REPORT_PREPARATION_STATUS,
} from '../../routes/dashboard/models';


@UntilDestroy()
@Component( {
  selector:        'ume-user-notifications',
  templateUrl:     './user-notifications.component.html',
  styleUrls:       [ './user-notifications.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class UserNotificationsComponent {
  @Input() isMenu = false;
  @Input() isVirtualPremium = false;

  @Input()
  get selectedForAssessmentSummary(): number {
    return Number( this._selectedForAssessmentSummary );
  }

  set selectedForAssessmentSummary( value: number | null ) {
    this._selectedForAssessmentSummary = value;
  }

  @Input()
  set ddqSummary( ddq: DDQSummary | null ) {
    if ( ddq ) {
      this._ddqSummary = ddq;
      this.ddqNotifications = ddqNotifications();
      this.hasDraftDDQ = ddq.status === DDQ_SUMMARY_STATUS.DRAFT;
    }
  }

  get ddqSummary(): DDQSummary {
    return this._ddqSummary;
  }

  readonly oversightStatus = OVERSIGHT_STATUS;
  readonly reportPreparationStatus = REPORT_PREPARATION_STATUS;
  readonly popTriggers = NgxFloatUiTriggers;

  public pendingRequests$: Observable<DashboardPendingRequestsSummary> = pendingRequests$;
  public notInvitedCompanies$: Observable<DashboardNotInvitedCompaniesSummary> = notInvitedCompanies$;
  public reportingSummary$: Observable<IDashboardReportingConfiguration> = reportingSummary$;
  public actionRaised$: Observable<DashboardActionRaisedSummary | null> = actionRaised$;
  public followups$: Observable<IFollowupSummary | null> = followups$;
  public numberOfSharing$: Observable<number> = numberOfSharing$;

  public requestReceived = 0;
  public hasDraftDDQ = false;
  public ddqNotifications!: MyProfileDDQNotifications;

  private _oversightSummary!: DashboardOversightSummaryBean;
  private _ddqSummary!: DDQSummary;
  private _selectedForAssessmentSummary: number | null = 0;

  constructor( private router: Router ) {}

  public navRequestReceived(): void {
    if ( getShareAllEnabled() ) {
      this.router.navigate(
        [ '/', 'company', 'my-network', 'list' ],
        {
          queryParams: {
            oversightStatus: this.oversightStatus.REQUEST_RECEIVED,
            shareAll:        true,
          },
        } ).then();
    } else {
      this.router.navigate(
        [ '/', 'company', 'my-network', 'list' ],
        {
          queryParams: {
            oversightStatus:  this.oversightStatus.REQUEST_RECEIVED,
            requestsNotReady: true,
          },
        } ).then();
    }
  }

  public navSelectedForAssessment(): void {
    this.router.navigate(
      [ '/', 'company', 'my-network', 'list' ],
      {
        queryParams: { logicalStatus: 'NEW' },
      },
    ).then();
  }

  public navHasDDQRequest(): void {
    if ( this.ddqNotifications.ddqNotStarted ) {
      this.router.navigate( [ '/', 'questionnaire', 'start' ] ).then();
    }

    if ( this.ddqNotifications.ddqNotCompleted ) {
      this.router.navigate( [ '/', 'questionnaire', 'edit' ] ).then();
    }
  }

  public navFollowUps(): void {
    this.router.navigate( [ '/', 'my-profile', 'followups' ] ).then();
  }

  public navToMyFiles(): void {
    this.router.navigate( [ '/', 'my-profile', 'policy-files' ] ).then();
  }

  public navToMyKPI(): void {
    this.router.navigate( [ '/', 'my-profile', 'kpi', 'form-view' ] ).then();
  }

  public navToUKCD(): void {
    this.router.navigate( [ '/', 'my-profile', 'reporting', 'submit-report' ] ).then();
  }

  public navNotificationOptions(): void {
    if ( this.hasDraftDDQ ) {
      this.router.navigate( [ '/', 'questionnaire', 'edit' ] ).then();
    }

    if ( this.ddqNotifications.ddqNotStarted ) {
      this.router.navigate( [ '/', 'questionnaire', 'start' ] ).then();
    }
  }

  public navActionRaised(): void {
    this.router.navigate(
      [ '/', 'company', 'my-network', 'list' ],
      {
        queryParams: { actionRaised: 'YES' },
      },
    ).then();
  }

  public navNumberOfSharing(): void {
    const forTenDays = moment().subtract( 10, 'days' ).format( 'yyyy-MM-DD' );

    this.router.navigate(
      [ '/', 'company', 'my-network', 'list' ],
      {
        queryParams: {
          dateFrom: forTenDays,
        },
      } ).then();
  }

  public navKPINotInvited(): void {
    this.router.navigate(
      [ '/', 'company', 'my-network', 'list' ],
      {
        queryParams: { oversightStatus: this.oversightStatus.NOT_INVITED },
      },
    ).then();
  }

  public navReporting(): void {
    this.router.navigate( [ '/', 'my-profile', 'reporting' ] ).then();
  }
}
