<div [ngStyle]="{'padding-top.px': isVirtualPremium ? 24 : 0}">
  @if (!isMenu) {
    <h2>Notifications</h2>
  }

  <ul
    class="notifications-list"
    [ngClass]="{'for-menu': isMenu}"
    [ngStyle]="{'padding-top.px': isVirtualPremium ? 8 : 0}"
  >
    @if (pendingRequests$ | async; as pendingRequests) {
      @if (pendingRequests.countReceivedRequests > 0) {
        <li>
          <div
            class="n-item"
            (click)="navRequestReceived()">
            <div class="n-item-content">
              <div class="n-item-number">
                <span class="n-badge">{{ pendingRequests.countReceivedRequests }}</span>
              </div>
              <p>
                {{ pendingRequests.countReceivedRequests }} of your counterparties would like to connect with you.
              </p>
            </div>
          </div>
        </li>
      }

      @if (pendingRequests.nroQuestionnaireRequests > 0) {
        <li>
          <div
            class="n-item"
            (click)="navHasDDQRequest()">
            <div class="n-item-content">
              <div
                class="n-item-number"
                [floatUi]="companiesPopover"
                [showTrigger]="popTriggers.hover"
                [timeoutAfterShow]="3000"
                [showDelay]="100"
                [appendTo]="'body'">
                <span class="n-badge">{{ pendingRequests.nroQuestionnaireRequests }}</span>
              </div>
              <p>
                <span>{{ pendingRequests.nroQuestionnaireCompanies }}</span>
                of your counterparties request your due diligence information - please complete your questionnaire.
              </p>
              <float-ui-content #companiesPopover>
                <ul class="popper-list">
                  @for (company of pendingRequests.companies; track company.guid) {
                    <li>
                      @if (company.country.code) {
                        <i [ngClass]="['icon-flag-' + company.country.code]"></i>
                      }
                      <span class="ellipsis">{{ company.companyName }}</span>
                    </li>
                  }
                </ul>
              </float-ui-content>
            </div>
          </div>
        </li>
      }

      @if (pendingRequests.nroFileRequests > 0) {
        <li>
          <div
            class="n-item"
            (click)="navToMyFiles()">
            <div class="n-item-content">
              <div
                class="n-item-number"
                [floatUi]="filesCompaniesPopover"
                [showTrigger]="popTriggers.hover"
                [timeoutAfterShow]="3000"
                [showDelay]="100"
                [appendTo]="'body'">
                <span class="n-badge">{{ pendingRequests.nroFileRequests }}</span>
              </div>
              <p>
                <span>&nbsp;{{ pendingRequests.nroFileCompanies }}&nbsp;</span>
                of your counterparties request your file information - please upload your files.
              </p>
              <float-ui-content #filesCompaniesPopover>
                <ul class="popper-list">
                  @for (company of pendingRequests.fileRequestCompanies; track company.guid) {
                    <li>
                      @if (company.country.code) {
                        <i [ngClass]="['icon-flag-' + company.country.code]"></i>
                      }
                      <span class="ellipsis">{{ company.companyName }}</span>
                    </li>
                  }
                </ul>
              </float-ui-content>
            </div>
          </div>
        </li>
      }

      @if (pendingRequests.nroKpiRequests > 0) {
        <li>
          <div
            class="n-item"
            (click)="navToMyKPI()">
            <div class="n-item-content">
              <div
                class="n-item-number"
                [floatUi]="kpiCompaniesPopover"
                [showTrigger]="popTriggers.hover"
                [timeoutAfterShow]="3000"
                [showDelay]="100"
                [appendTo]="'body'">
                <span class="n-badge">{{ pendingRequests.nroKpiRequests }}</span>
              </div>
              <p>
                <span>&nbsp;{{ pendingRequests.nroKpiCompanies }}&nbsp;</span>
                of your counterparties request your KPI information - please upload your KPI.
              </p>
              <float-ui-content #kpiCompaniesPopover>
                <ul class="popper-list">
                  @for (company of pendingRequests.kpiRequestCompanies; track company.guid) {
                    <li>
                      @if (company.country.code) {
                        <i [ngClass]="['icon-flag-' + company.country.code]"></i>
                      }
                      <span class="ellipsis">{{ company.companyName }}</span>
                    </li>
                  }
                </ul>
              </float-ui-content>
            </div>
          </div>
        </li>
      }

      @if (pendingRequests.nroReportRequests > 0) {
        <li>
          <div
            class="n-item"
            (click)="navToUKCD()">
            <div class="n-item-content">
              <div
                class="n-item-number"
                [floatUi]="reportCompaniesPopover"
                [showTrigger]="popTriggers.hover"
                [timeoutAfterShow]="3000"
                [showDelay]="100"
                [appendTo]="'body'">
                <span class="n-badge">{{ pendingRequests.nroReportRequests }}</span>
              </div>
              <p>
                <span>&nbsp;{{ pendingRequests.nroReportRequestCompanies }}&nbsp;</span>
                of your counterparties request your DFT information - please upload your DFT.
              </p>
              <float-ui-content #reportCompaniesPopover>
                <ul class="popper-list">
                  @for (company of pendingRequests.reportRequestCompanies; track company.guid) {
                    <li>
                      @if (company.country.code) {
                        <i [ngClass]="['icon-flag-' + company.country.code]"></i>
                      }
                      <span class="ellipsis">{{ company.companyName }}</span>
                    </li>
                  }
                </ul>
              </float-ui-content>
            </div>
          </div>
        </li>
      }
    }

    @if (notInvitedCompanies$ | async; as notInvited) {
      @if (notInvited.nroKPINotInvited > 0) {
        <li>
          <div
            class="n-item"
            (click)="navKPINotInvited()">
            <div class="n-item-content">
              <div
                class="n-item-number"
                [floatUi]="kpiNotInvited"
                [showTrigger]="popTriggers.hover"
                [timeoutAfterShow]="3000"
                [showDelay]="100"
                [appendTo]="'body'">
                <span class="n-badge">{{ notInvited.nroKPINotInvited }}</span>
              </div>
              <p>
                You have {{ notInvited.nroKPINotInvited }} counterparty invitations to send.
              </p>
              <float-ui-content #kpiNotInvited>
                <ul class="popper-list">
                  @for (company of notInvited.kpiNotInvitedCompanies; track company.guid) {
                    <li>
                      @if (company.country.code) {
                        <i [ngClass]="['icon-flag-' + company.country.code]"></i>
                      }
                      <span class="ellipsis">{{ company.companyName }}</span>
                    </li>
                  }
                </ul>
              </float-ui-content>
            </div>
          </div>
        </li>
      }
    }

    @if (ddqNotifications && (ddqNotifications.ddqExpiring || ddqNotifications.ddqExpired)) {
      <li>
        <div
          class="n-item"
          (click)="navNotificationOptions()">
          <div class="n-item-content">
            <div class="n-item-number"></div>
            @if (ddqNotifications.ddqExpiring) {
              <p>
                Your due diligence questionnaire is about to expire - please update your responses.
              </p>
            }

            @if (ddqNotifications.ddqExpired) {
              <p>
                Your due diligence questionnaire has expired - please update your responses.
              </p>
            }
          </div>
        </div>
      </li>
    }

    @if (actionRaised$ | async; as actionRaised) {
      <li>
        <div
          class="n-item"
          (click)="navActionRaised()">
          <div class="n-item-content">
            <div class="n-item-number">
              <span class="n-badge">{{ actionRaised.nroActionsRaised }}</span>
            </div>
            <p>
              ume support have raised {{ actionRaised.nroActionsRaised }} actions which you need to review.
            </p>
          </div>
        </div>
      </li>
    }

    @if (followups$ | async; as followups) {
      <li>
        <div
          class="n-item"
          (click)="navFollowUps()">
          <div class="n-item-content">
            <div class="n-item-number">
              <span class="n-badge">{{ followups.newFollowups + followups.draftFollowups }}</span>
            </div>
            <p>
              Your counterparties have asked you {{ followups.newFollowups + followups.draftFollowups }}
              questions which you have not yet answered.
            </p>
          </div>
        </div>
      </li>
    }

    @if (numberOfSharing$ | async; as numberOfSharing) {
      <li>
        <div
          class="n-item"
          (click)="navNumberOfSharing()">
          <div class="n-item-content">
            <div class="n-item-number">
              <span class="n-badge">{{ numberOfSharing }}</span>
            </div>
            <p>You have <span>{{ numberOfSharing }}</span> new connections in the last 10 days.</p>
          </div>
        </div>
      </li>
    }

  </ul>
</div>

<!-- Notifications fot Virtual Premium user -->
@if (isVirtualPremium) {
  <div>
    @if (!isMenu) {
      <h2 class="notifications-title">Assessment updates</h2>
    }

    @if (selectedForAssessmentSummary > 0) {
      <ul class="notifications-list">
        <li>
          <div class="n-item" (click)="navSelectedForAssessment()">
            <div class="n-item-content">
              <div class="n-item-number">
                <span class="n-badge">{{ selectedForAssessmentSummary }}</span>
              </div>
              <p>
                {{ selectedForAssessmentSummary }} New selected for assessment.
              </p>
            </div>
          </div>
        </li>
      </ul>
    }
  </div>
}
